import React from "react";
import "./services.css";
import Abita from "./images/Abita.jpg";
import covington from "./images/covington.jpg";
import hammond from "./images/hAmmond.jpeg";
import madisonville from "./images/Madisonville.jpg";
import mandeville from "./images/Mandeville.jpg";
import new_orlean from "./images/New Orleans.jpg";
import ponchatoula from "./images/Ponchatoula.jpg";
import slidell from "./images/Slidell.jpg";
// import ImageSlider from "./ImageSlider";

const Service = () => {
//   const areay1 = [
//     {
//       image: madisonville,
//       text: "Madisonville",
//     },
//     {
//       image: mandeville,
//       text: "Mandiville",
//     },
//     {
//       image: Abita,
//       text: "Abita",
//     },
//     {
//       image: covington,
//       text: "Covington",
//     },
//   ];
//   const array2 = [
//     {
//       image: new_orlean,
//       text: "New Orleans",
//     },
//     {
//       image: slidell,
//       text: "Slidell",
//     },
//     {
//       image: ponchatoula,
//       text: "Ponchatoula",
//     },
//     {
//       image: hammond,
//       text: "Hammond",
//     },
//   ];
//   const width = window.screen.availWidth;
  return (
    <section className="four" id="service_area">
      <span className="head">Service Areas</span>
      <h2>Your Local Detailing Destination</h2>
      <p className="sub_text">
        Discover the KB's Detailing Difference in Your Area
      </p>
      <div className="solo">
        <h5>St. Tammany Parish</h5>
        <article className="showroom">
          <div>
            <img src={madisonville} alt="" />
            <p>Madisonville</p>
          </div>
          <div>
            <img src={mandeville} alt="" />
            <p>Mandeville</p>
          </div>
          <div>
            <img src={Abita} alt="" />
            <p>Abita</p>
          </div>
          <div>
            <img src={covington} alt="" />
            <p>Covington</p>
          </div>
        </article>
        
      </div>
      <div className="solo">
        <h5>We Also Serve</h5>
        <article className="showroom">
          <div>
            <img src={new_orlean} alt="" />
            <p>New Orleans</p>
          </div>
          <div>
            <img src={slidell} alt="" />
            <p>Slidell</p>
          </div>
          <div>
            <img src={ponchatoula} alt="" />
            <p>Ponchatoula</p>
          </div>
          <div>
            <img src={hammond} alt="" />
            <p>Hammond</p>
          </div>
        </article>
        
      </div>
      <p className="big">Not within these areas? Let's Talk!</p>
      <p className="little">
        We're always looking to expand our reach. If you're not located in our
        listed service areas, please reach out - we might still be able to
        accommodate you.
      </p>
      <a href="tel:+15042315484" className="button">
        Give us a call
      </a>
    </section>
  );
};

export default Service;
