import React from "react";
import logo from "./images/KB DETAILING 1.svg";
import MailIcon from "@mui/icons-material/Mail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import instagram from './images/instagram.svg'
import facebook from "./images/facebook.svg";
import left from './images/Rectangle 17.png'
import right from './images/Rectangle 18.png'

const Footer = () => {
  return (
    <footer>
      <div className="main">
        <img className="logo" src={logo} alt="" />
        <section>
          <div>
            <h6 id="about">About Us</h6>
            <p>
             At KB’s Detailing, we go beyond cleaning - we rejuvenate, protect, and elevate your vehicle’s aesthetic appeal. Experience excellence in detailing with us, where each service reflects our dedication to perfection. Your satisfaction is our signature finish.
            </p>
          </div>
          <div>
            {" "}
            <h6 className="touch">Services</h6>
            <span>Auto Detailing</span>
            <span>Paint Correction</span>
            <span>Tinting</span>
            <span>Ceramic Coating</span>
            <span>Marine</span>
            <span>Fleet Services</span>
          </div>
          <div>
            <h6 className="touch">Contact us</h6>
            <p>
              <MailIcon />
              <span>Kbdetailing21@gmail.com</span>
            </p>
            <a href="tel:+15042315484">
              <LocalPhoneIcon />
              <span>(+1) 504-231-5484</span>
            </a >
          </div>
        </section>
      </div>
      <div className="sub_p">
        <img src={left} alt="" />
        <nav>
          <a href="https://www.instagram.com/kbdetailing21">
            <img src={instagram} alt="" />
          </a>
          <a href="https://m.facebook.com/kbdetailing21/">
            <img src={facebook} alt="" />
          </a>
        </nav>
        <img src={right} alt="" />
      </div>
    </footer>
  );
};

export default Footer;
