import React from "react";
import StarIcon from "@mui/icons-material/Star";
import google from "./images/google.png";
import CommentSlider from "./CommentSlider";

const Testimonial = () => {
  const array = [
    {
      name: "Creed Autin",
      rating: 5,
      comment:
        "Got my truck done by Kollin full detail and came out beautiful. Had some stains in the carpet that I didn’t think would come out but he made sure they did looks better than new highly recommend him.",
    },
    {
      name: "dianne bentel",
      rating: 5,
      comment:
        "**Best Mobile Detail Service in South Louisiana!!!!!!Always on time, makes booking easy and leaves my car with a new car look every time!",
    },
    {
      name: "Delilah Jackson",
      rating: 5,
      comment:
        "My family and I recently used KB’s Detailing service for all five of our cars and we were very impressed and thankful. They did an excellent job removing all the trash and gunk, and the staff was friendly, respectful, and punctual and exceeded our expectations. Their commitment to quality and convenience stood out. Highly recommend!",
    },
    {
      name: "Kelli Ann Boatright",
      rating: 5,
      comment:
        "Kollin and Zac came to my place and did an EXCELLENT job👍🏻👍🏻 This is my vehicle 10 days later💥💥 They did such FANTASTIC work, I decided I’m going to use their services MONTHLY🎯🎯",
    },
    {
      name: "Travis Johnson",
      rating: 5,
      comment:
        "Kollin and his team always do a great job. Finding a mobile detailer who is dependable and prompt has been a challenge in New Orleans, so I am thankful to have been referred to him.  KB's great prices and dependable service were the driving factors behind my spouse and I setting up monthly, recurring detailing services.",
    },
    {
      name: "Ronald Landeche",
      rating: 5,
      comment:
        "KB's Detailing is the only mobile car detailing service I recommend to all my friends and family. I was impressed with how thorough the team was when detailing my car.   KB's mobile car detailing service is the perfect solution for busy people who want to keep their cars looking great.",
    },
  ];
  const width = window.screen.availWidth;

  return (
    <section className="three" id="testimonials">
      <span className="head">Customer reviews</span>
      <h2>Our Wall Of Love</h2>
      <p className="sub_text">
        Have a glimpse of what our customers are saying on Google
      </p>
      <article>
        {width > 500 ? (
          <>
            {array.map((val, index) => {
              return (
                <div className="comments" key={index}>
                  <div >  
                    <p>
                      <span className={val.rating > 1 ? 'positive': '' }>
                        <StarIcon />
                      </span>
                      <span  className={val.rating > 1 ? 'positive': ''}>
                        <StarIcon />
                      </span>
                      <span  className={val.rating > 1 ? 'positive': ''}>
                        <StarIcon />
                      </span>
                      <span  className={val.rating > 1 ? 'positive': ''}>
                        <StarIcon />
                      </span>
                      <span  className={val.rating > 1 ? 'positive': ''}>
                        <StarIcon />
                      </span>
                    </p>
                    <img src={google} alt="" />
                  </div>
                  <p className="text">
                      {val.comment}
                  </p>
                  <span className="name">- {val.name}</span>
                </div>
              );
            })}
          </>
        ) : (
          <CommentSlider data={array} interval={4500}/>
        )}
      </article>
    </section>
  );
};

export default Testimonial;
