import React from "react";
import logo from "./images/KB DETAILING 1.svg";
import { Link } from "react-scroll";
const Header = () => {
  function nav() {
    const nav = document.getElementById("nav");
    const hamburger = document.getElementById('nav-icon4')

    const body = document.getElementsByTagName("body")[0];
    nav.style.right = "-60vw";
    hamburger.classList.toggle('open')
    body.style.overflowY = "scroll";
  }
  function toggle_nav(e) {
    const nav = document.getElementById("nav");
    const body = document.getElementsByTagName("body")[0];
    if (!e.target.classList.contains("open")) {
      nav.style.display = "flex";
      setTimeout(() => {
        nav.style.right = "0vw";
        body.style.overflow = "hidden";
      }, 100);
    } else {
      nav.style.right = "-60vw";
      body.style.overflowY = "scroll";
      setTimeout(() => {
        nav.style.display = "none";
      }, 400);
    }
    e.target.classList.toggle("open");
  }
  return (
    <header>
      <img src={logo} alt="" />
      <aside className="hamburger">
        <div onClick={toggle_nav} id="nav-icon4">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </aside>
      <nav id="nav">
        <section>
          <Link
            onClick={nav}
            activeClass="active"
            spy={true}
            smooth={true}
            offset={100}
            duration={500}
            to="services"
          >
            Services
          </Link>
          <Link
            onClick={nav}
            activeClass="active"
            spy={true}
            smooth={true}
            // offset={-140}
            duration={500}
            to="testimonials"
          >
            Testimonials
          </Link>
          <Link
            onClick={nav}
            activeClass="active"
            spy={true}
            smooth={true}
            // offset={-140}
            duration={500}
            to="service_area"
          >
            Service Area
          </Link>
        </section>
        <Link
          onClick={nav}
          activeClass="active"
          spy={true}
          className="contact_us"
          smooth={true}
          // offset={-180}
          duration={500}
          to="contact_us"
        >
          Contact Us
        </Link>
      </nav>
    </header>
  );
};

export default Header;
