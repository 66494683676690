import React, { useState, useEffect } from 'react';
import StarIcon from "@mui/icons-material/Star";
import google from "./images/google.png";

const CommentSlider = ({ data, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      // Increment the index to show the next image
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, interval);

    // Clear the interval when the component is unmounted or the data change
    return () => clearInterval(timer);
  }, [currentIndex, interval, data]);

  return (
    <div className="image-slider">
            {data.map((val, index) => {
              if(index === currentIndex){
              return (
                <div className ={`comments ${'active'}`} key={index}>
                  <div >  
                    <p>
                      <span className={val.rating > 1 ? 'positive': '' }>
                        <StarIcon />
                      </span>
                      <span  className={val.rating > 1 ? 'positive': ''}>
                        <StarIcon />
                      </span>
                      <span  className={val.rating > 1 ? 'positive': ''}>
                        <StarIcon />
                      </span>
                      <span  className={val.rating > 1 ? 'positive': ''}>
                        <StarIcon />
                      </span>
                      <span  className={val.rating > 1 ? 'positive': ''}>
                        <StarIcon />
                      </span>
                    </p>
                    <img src={google} alt="" />
                  </div>
                  <p className="text">
                      {val.comment}
                  </p>
                  <span className="name">- {val.name}</span>
                </div>
              )}
              else{
                return('')
              }
            }
            
            )}
    </div>
  );
};

export default CommentSlider;
