import "./App.css";
import ProfessionalDetailing from "./Components/ProfessionalDetailing";
import WhatWeDo from "./Components/WhatWeDo";
import man from "./Components/images/lb.png";
import landman from "./Components/images/about us.jpg";
import Form from "./Components/Form";
import Footer from "./Components/Footer";
import Testimonial from "./Components/Testimonial";
import Service from "./Components/Service";
import systemx from "./Components/images/systemx-logo [Recovered] 1.png";

function App() {
  const width = window.screen.availWidth;
  return (
    <div className="App">
      <ProfessionalDetailing />
      <WhatWeDo />
      <div className="systemx">
        <p>Proudly Certified By </p>
        <img src={systemx} alt="" />
      </div>
      <Testimonial />
      <Form />
      <section className="sub">
        <aside>
          <h4>Who we are</h4>
          <p>
            At KB’s Detailing, we go beyond cleaning - we rejuvenate, protect,
            and elevate your vehicle’s aesthetic appeal. Experience excellence
            in detailing with us, where each service reflects our dedication to
            perfection. Your satisfaction is our signature finish.
          </p>
        </aside>
        <img src={width < 900 ? man : landman} alt="" />
      </section>
      <Service />
      <Footer />
    </div>
  );
}

export default App;
