import React, { useState } from "react";
import right from "./images/Ellipse 1111.png";
import left from "./images/Ellipse 1110.png";
import messag from "./images/Frame (2).svg";
import instagram from "./images/Frame (1).svg";
import facebook from "./images/Frame.svg";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDrop from './images/Vectordrop.png'

const Form = () => {
  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [service, SetService] = useState("");
  const [message, SetMessage] = useState("");
  const [text,setText] = useState('Submit')
  const url = "https://kbs-backend.onrender.com/contact";
  const data = {
    name: name,
    email: email,
    message:message,
    service:service
  };

  function view(e) {
    const elem = e.target;
    const option = document.getElementById("option");
    const stylez = option.style.display;
    if (stylez === "" || stylez === "none") {
      elem.style.transform = "rotateZ(180deg)";
      option.style.display = "block";
    } else {
      elem.style.transform = "rotateZ(0deg)";
      option.style.display = "none";
    }
  }
  function Set(e) {
    const option = document.getElementById("option");
    const input = document.getElementById("service");
    const arrow = document.getElementsByClassName("arrow")[0];
    arrow.style.transform = "rotateZ(0deg)";
    option.style.display = "none";
    input.readOnly = true;
    SetService(e.target.innerHTML);
  }
  function serve() {
    const input = document.getElementById("service");
    SetService(" ");
    const option = document.getElementById("option");
    const arrow = document.getElementsByClassName("arrow")[0];
    arrow.style.transform = "rotateZ(0deg)";
    option.style.display = "none";
    input.readOnly = false;
    input.focus();
  }

  function submit() {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
      setText('Sent')
      SetName('')
      SetEmail('')
      SetMessage('')
      SetService('')
      setTimeout(()=>{
        setText('Submit')
      },[500])
  }
  return (
    <section id="contact_us" className="form">
      <img className="right" src={right} alt="" />
      <img className="left" src={left} alt="" />
      <div className="parent">
        <article>
          <h1>Let’s Talk</h1>
          <p>
            Discover premium detailing for cars, boats, and fleets. Connect with
            us for top-tier paint correction, ceramic coatings, and
            tinting—quality service guaranteed.
          </p>
          <h5>Email</h5>
          <a href="mailto:Kbdetailing21@gmail.com">
            <img src={messag} alt="" /> <span>Kbdetailing21@gmail.com</span>{" "}
          </a>
          <h5>Socials</h5>
          <a href="https://www.instagram.com/kbdetailing21">
            <img src={instagram} alt="" /> <span>Instagram</span>{" "}
          </a>
          <a href="https://m.facebook.com/kbdetailing21/">
            <img src={facebook} alt="" /> <span>Facebook</span>{" "}
          </a>
        </article>
        <aside>
          <div>
            <span>Name</span>
            <input
              value={name}
              onChange={(e) => {
                SetName(e.target.value);
              }}
              type="text"
              placeholder="Name"
            />
          </div>
          <div>
            <span>Email</span>
            <input
              value={email}
              onChange={(e) => {
                SetEmail(e.target.value);
              }}
              type="email"
              placeholder="Email"
            />
          </div>
          <div>
            <span>What service are you interested in</span>
            <p className="services">
              <input
                id="service"
                value={service}
                onChange={(e) => {
                  SetService(e.target.value);
                }}
                readOnly
                type="text"
                placeholder="Select service category"
              />
              <span onClick={view} className="arrow">
                <img src={ArrowDrop} alt="" />
              </span>
            </p>
            <section id="option">
              <p onClick={Set}>Auto Detailing</p>
              <p onClick={Set}>Paint Correction</p>
              <p onClick={Set}>Tinting</p>
              <p onClick={Set}>Ceramic Coating</p>
              <p onClick={Set}>Marine</p>
              <p onClick={Set}>Fleet Services</p>
              <p onClick={serve}>(Others)</p>
            </section>
          </div>
          <div>
            <span>Message</span>
            <textarea
              id="message"
              value={message}
              onChange={(e) => {
                SetMessage(e.target.value);
              }}
              placeholder="Let us know how we can assist you"
            />
          </div>
          <button onClick={submit}>{text}</button>
        </aside>
      </div>
    </section>
  );
};

export default Form;
