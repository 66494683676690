import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import auto_dealing from "./images/auto_dealing.png";
import paint_correction from "./images/paint correction.png";
import tinting from "./images/tinting.png";
import ceramic_coating from "./images/ceramic_coating.png";
import marine from "./images/marine.png";
import fleet_service from "./images/fleet services.png";
import { Link } from "react-scroll";

const WhatWeDo = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section id="services" className="two">
      <span className="head">Our services</span>
      <h2>What We Do</h2>
      <p className="sub_text">Our popular detailing services</p>
      <section className="desktop">
        <div data-aos="zoom-in" data-aos-delay="100">
          <img src={auto_dealing} alt="" />
          <p>Auto Detailing</p>
        </div>
        <div data-aos="zoom-in" data-aos-delay="200">
          <img src={paint_correction} alt="" />
          <p>Paint Correction</p>
        </div>
        <div data-aos="zoom-in" data-aos-delay="300">
          <img src={tinting} alt="" />
          <p>Tinting</p>
        </div>
        <div data-aos="zoom-in" data-aos-delay="100">
          <img src={ceramic_coating} alt="" />
          <p>Ceramic Coating</p>
        </div>
        <div data-aos="zoom-in" data-aos-delay="200">
          <img src={marine} alt="" />
          <p>Marine</p>
        </div>
        <div data-aos="zoom-in" data-aos-delay="300">
          <img src={fleet_service} alt="" />
          <p>Fleet Services</p>
        </div>
      </section>
      <aside className="mobile">
        <section>
          <div>
            <img src={auto_dealing} alt="" />
            <p>Auto Detailing</p>
          </div>
          <div>
            <img src={paint_correction} alt="" />
            <p>Paint Correction</p>
          </div>
          <div>
            <img src={tinting} alt="" />
            <p>Tinting</p>
          </div>
          <div>
            <img src={ceramic_coating} alt="" />
            <p>Ceramic Coating</p>
          </div>
          <div>
            <img src={marine} alt="" />
            <p>Marine</p>
          </div>
          <div>
            <img src={fleet_service} alt="" />
            <p>Fleet Services</p>
          </div>
        </section>
        <section >
          <div>
            <img src={auto_dealing} alt="" />
            <p>Auto Detailing</p>
          </div>
          <div>
            <img src={paint_correction} alt="" />
            <p>Paint Correction</p>
          </div>
          <div>
            <img src={tinting} alt="" />
            <p>Tinting</p>
          </div>
          <div>
            <img src={ceramic_coating} alt="" />
            <p>Ceramic Coating</p>
          </div>
          <div>
            <img src={marine} alt="" />
            <p>Marine</p>
          </div>
          <div>
            <img src={fleet_service} alt="" />
            <p>Fleet Services</p>
          </div>
        </section>
      </aside>
      <p className="info">
        <span>Have a unique request?</span>
        <Link
          activeClass="active"
          spy={true}
          className="contact_us"
          smooth={true}
          duration={500}
          to="contact_us"
        >
          Contact us
        </Link>
      </p>
    </section>
  );
};

export default WhatWeDo;
